import { collectSSRPlaceholderDimensions } from '@atlassian/react-ufo/ssr-scripts';

// Runs with `type="module"` execute after HTML is parsed
// Code here runs after HTML body
if (window.__SSR_RENDERED__) {
	collectSSRPlaceholderDimensions(document, window);
	require('@confluence/favourite-button/ssr-scripts/ssrFavoriteButtonClickHandler');
	require('@confluence/restrictions/ssr-scripts/ssrRestrictionsButtonClickHandler');
	require('@confluence/watch-dialog/ssr-scripts/ssrWatchButtonClickHandler');
	require('@confluence/content-tools/ssr-scripts/ssrMoreActionButtonClickHandler');
	require('@confluence/comment-button/ssr-scripts/ssrCommentButtonClickHandler');
	require('@confluence/action-buttons/ssr-scripts/ssrSpaceStarTextButtonClickHandler');
	require('@confluence/confluence-analytics/ssr-scripts/ssrAnalyticsByLineClickHandler');
	require('@confluence/inline-comments-link-handler/ssr-scripts/ssrInlineCommentsClickHandler');
	require('@confluence/admin-announcement-banner/ssr-scripts/ssrDismissAdminAnnouncementBannerClickHandler');
	require('@confluence/view-page/ssr-scripts/ssrLegacyBridge');
}
